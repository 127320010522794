<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button status="my-orange" @click="showSearch(false)" v-if="seachStatus">隐藏查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-plus" @click="goPage('add')">添加</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-plus" @click="exportData()">导出</vxe-button>
      </template>
    </vxe-toolbar>
    <div class="search" v-if="seachStatus">
      <vxe-form title-align="right" title-width="100" title-colon :data="searchData" @submit="searchEvent" @reset="searchReset">
        <vxe-form-item title="名称" span="8">
          <vxe-input v-model="searchData.siteName" placeholder="请输入姓名" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item title="站点状态" span="8">
          <vxe-select v-model="searchData.siteStatus" placeholder="请选择站点状态" style="width:100%;">
            <vxe-option v-for="item in siteStatusList" :key="item.dictPidVal" :label="item.dictDesc" :value="item.dictPidVal"></vxe-option>
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item title="所属社区" span="8">
          <vxe-input v-model="searchData.community" placeholder="请输入所属社区" clearable></vxe-input>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">查询</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <div class="table">
      <vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData" :loading="loading">
        <template v-slot:siteName="{ row }">
          <p class="link-a" @click="toDetail('serviceStation', row)">{{ row.siteName }}</p>
        </template>
        <template v-slot:operation="{ row }">
          <vxe-button type="text" status="primary" @click="goPage('edit', row)">修改</vxe-button>
          <vxe-button type="text" status="primary" @click="remove(row.id)">删除</vxe-button>
          <vxe-button type="text" status="primary" @click="goPage('add')">添加</vxe-button>
        </template>
      </vxe-grid>
      <vxe-pager
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :page-sizes="tablePage.pageSizes"
        :total="tablePage.totalResult"
        :layouts="layouts"
        @page-change="pageChange"
      ></vxe-pager>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import api from '@/store/API/api'
export default {
  data() {
    return {
      siteStatusList: [],
      loading: false,
      seachStatus: false,
      searchData: {},
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 50,
          align: 'center',
        },
        {
          field: 'siteName',
          title: '名称',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
          slots: {
            default: 'siteName',
          },
        },
        {
          field: 'siteStatusName',
          title: '状态',
          showOverflow: true,
          align: 'center',
          minWidth: '70',
        },
        {
          field: 'nature',
          title: '性质',
          showOverflow: true,
          align: 'center',
          minWidth: '70',
        },
        {
          field: 'belongCm',
          title: '所属社区',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          field: 'operCompName',
          title: '运营企业',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
        },
        {
          title: '操作',
          width: 200,
          showOverflow: true,
          slots: {
            default: 'operation',
          },
        },
      ],
      tableData: [],
    }
  },
  computed: {
    ...mapState(['layouts']),
    ...mapGetters(['seqId']),
  },
  watch: {
    seqId() {
      // this.getLists()
    },
  },
  created() {
    this.initView()
    this.getDicList()
  },
  methods: {
    ...mapActions(['getServceStationList', 'deleteServiceStation', 'getSysDictServe', 'exportServiceStation']),
    initView() {
      this.getLists()
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    getLists() {
      this.loading = true
      this.getServceStationList({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          siteName: this.searchData.siteName,
          siteStatus: this.searchData.siteStatus,
          community: this.searchData.community,
        },
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      console.log(this.searchData)
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {}
      this.getLists()
    },
    remove(id) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          console.log('执行删除操作')
          this.delete(id)
        }
      })
    },
    delete(id) {
      this.deleteServiceStation({
        id: id,
      }).then((res) => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: 'success',
          })
          this.getLists()
        }
        this.loading = false
      })
    },
    getDicList() {
      this.getSysDictList('workSiteStatus', 'siteStatusList')
    },
    // 获取数据字典
    getSysDictList(code, codeList) {
      let params = {
        typeCode: code,
      }
      this.getSysDictServe(params).then((res) => {
        if (res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
    // 导出功能
    exportData() {
      let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
      url += api.exportServiceStation
      let myObj = {
        method: 'post',
        url,
        fileName: '服务站',
        params: {
          current: this.tablePage.currentPage,
          size: this.tablePage.pageSize,
          param: {
            siteName: this.searchData.siteName,
            siteStatus: this.searchData.siteStatus,
            community: this.searchData.community,
          },
        },
      }
      this.$tools.exportMethod(myObj)
    },
    goPage(type, itemData) {
      this.$router.push({
        name: 'AddServiceStation',
        params: {
          type: type,
          id: type == 'add' ? 'add' : itemData.id,
        },
        query: {
          item: type == 'add' ? 'add' : JSON.stringify(itemData),
        },
      })
    },
    toDetail(type, itemData) {
      this.$router.push({
        name: 'ServiceStationDetail',
        params: {
          id: itemData.id,
        },
        query: {
          type: type,
		  item: type == 'add' ? 'add' : JSON.stringify(itemData),
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.table {
  overflow-y: auto;
}
.wrapper {
  .datetime {
    display: flex;
    align-items: center;
    span {
      margin: 0 3px;
    }
  }
}
.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
